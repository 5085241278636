import styled from 'styled-components'
import { toRem } from 'helpers/index'
import {
  typography,
  space,
  color,
  layout,
  ColorProps,
  SpaceProps,
  TypographyProps,
  LayoutProps,
} from 'styled-system'

type BaseProps = ColorProps &
  SpaceProps &
  TypographyProps &
  LayoutProps & { underline?: boolean }

export const HeroTitle = styled.h1<BaseProps>`
  font-family: 'Clash Grotesk', sans-serif;
  font-weight: 600;
  font-size: ${toRem(64)};
  line-height: ${toRem(54.5)};
  color: ${(props) => props.theme.colors.primary.main};
  text-transform: capitalize;
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const TitleOne = styled.h2<BaseProps>`
  font-family: 'Clash Grotesk';
  font-weight: 600;
  font-size: ${toRem(40)};
  line-height: ${toRem(48)};
  ${space}
  ${color}
  ${typography}
`

export const TitleTwo = styled.h3<BaseProps>`
  font-family: 'Clash Grotesk';
  font-weight: 600;
  font-size: ${toRem(32)};
  line-height: ${toRem(39)};
  ${space}
  ${color}
    ${typography}
        ${layout}
`

export const TitleThree = styled.h3<BaseProps>`
  font-family: 'Clash Grotesk';
  font-weight: 600;
  font-size: ${toRem(24)};
  line-height: ${toRem(30)};
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const SubHeading = styled.h4<BaseProps>`
  font-family: 'Clash Grotesk';
  font-weight: 500;
  font-size: ${toRem(20)};
  line-height: ${toRem(30)};
  ${space}
  ${color}
    ${typography}
        ${layout}
`

export const SubHeadingBold = styled.h4<BaseProps>`
  font-family: 'Clash Grotesk';
  font-weight: 600;
  color: #1d2939;
  font-size: ${toRem(20)};
  line-height: ${toRem(30)};
  ${space}
  // ${color}
  ${typography}
      ${layout}
`

export const BodyLarge = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: ${toRem(18)};
  line-height: ${toRem(32)};
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const BodyLargeBold = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  font-size: ${toRem(18)};
  line-height: ${toRem(28)};
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const BodyNormal = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: ${toRem(16)};
  line-height: ${toRem(28)};
  letter-spacing: -0.02em;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'unset')};
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const BodyNormalBold = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: ${toRem(16)};
  line-height: ${toRem(30)};
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const BodyNormalSemiBold = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: ${toRem(16)};
  line-height: ${toRem(28)};
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const BodySmallSemiBold = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
  ${space}
  ${color}
  ${typography}
      ${layout}
`

export const BodySmall = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: ${toRem(14)};
  line-height: ${toRem(24)};
  ${space}
  ${color}
    ${typography}
    ${layout}
`
export const Paragraph = styled.p<BaseProps>`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: ${toRem(13)};
  line-height: ${toRem(16)};
  ${space}
  ${color}
    ${typography}
    ${layout}
`

export const CTAButtonText = styled.p`
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: ${toRem(16)};
  line-height: ${toRem(24)};
  /* identical to box height, or 150% */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  /* my therapist blue */

  ${space}
  ${color}
    ${typography}
    ${layout}
`
