import React from 'react'
import Header from './Header'
import NotesView from 'components/libs/Notes'
import styled from 'styled-components'
import {
  BodyNormalSemiBold,
  BodySmall,
  TitleThree,
} from 'components/base/typography'
import Dropdown from 'components/libs/Dropdown'
import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import ClientTabWidget from 'components/libs/ClientTabWidget'
import { Box } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'
import useFetchClient from 'hooks/clients/useFetchClient'
import { useParams } from 'react-router-dom'

export const AccountAvatar = styled.div`
  align-items: center;
  display: flex;
`

export const AccountDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Wrapper = styled.section`
  padding: 30px 20px;
`

export const WidgetContainer = styled.div`
  margin-top: 40px;
`

const Client = () => {
  const { id } = useParams()

  const { data: clientData, isLoading } = useFetchClient(Number(id))

  return (
    <div>
      <Header />
      <Wrapper>
        <HeaderSection>
          <div>
            {isLoading ? (
              <ProfileSkeleton />
            ) : (
              <AccountDetails>
                <AccountAvatar>
                  <img
                    src={clientData?.avatar}
                    alt={'Woman'}
                    style={{
                      width: '70px',
                      height: '70px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      borderWidth: clientData?.is_prime ? '3px' : 0,
                      borderColor: '#FFD700',
                      borderStyle: 'solid',
                    }}
                  />
                </AccountAvatar>
                <div>
                  <TitleThree color={'#101928'} fontSize={'28px'}>
                    {clientData?.first_name} {clientData?.last_name}
                  </TitleThree>
                  <BodySmall color={'#475467'}>
                    @{clientData?.username}
                  </BodySmall>
                </div>
              </AccountDetails>
            )}
          </div>

          {/* <Button
            variant={ButtonState.TERTIARY}
            padding={'6px 40px'}
            type={'button'}
            onClick={() => console.log('HELLO')}
          >
            Action
          </Button> */}
        </HeaderSection>

        <WidgetContainer>
          <ClientTabWidget defaultValue="overview" />
        </WidgetContainer>
      </Wrapper>
    </div>
  )
}

export const ProfileSkeleton = () => {
  return (
    <Box
      style={{
        width: 'fit-content',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '20px',
      }}
    >
      <Box>
        <Skeleton
          width="100px"
          height="100px"
          style={{ borderRadius: '50%' }}
        />
      </Box>

      <Skeleton width={'200px'} count={2} />
      <Skeleton />
    </Box>
  )
}

export default Client
