import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TherapistLogo from '../../../assets/img/image.png'
import {
  SideNavStyle,
  SideNavTop,
  NavItem,
  SideNavCenter,
  SideNavFooter,
  Hr,
  IntercomBtn,
} from './style'
import {
  HomeIcon,
  LogoutIcon,
  TherapyIcon,
  HelpDesk,
  Card,
  NoteIcon,
  Chat,
} from '../../../assets/svg'
import { BodyNormal, CTAButtonText } from '../../base/typography'
import Modal from '../Modal'
import LogoutModal from '../Modal/Logout'
import { useIntercom } from 'react-use-intercom'
import DropdownMenu from './DropdownMenu'
import { toggleSessionDetails } from 'store/modules/modals/actions'
import { useDispatch } from 'react-redux'

const navItems = [
  {
    icon: (props: any) => <HomeIcon {...props} />,
    text: 'Dashboard',
    path: '/dashboard',
  },
  {
    icon: (props: any) => <TherapyIcon {...props} />,
    text: 'Bookings',
    path: '/dashboard/bookings',
  },
  {
    icon: (props: any) => <NoteIcon {...props} />,
    text: 'Notes',
    path: '/dashboard/notes',
  },
  // {
  //   icon: (props: any) => <Profile {...props} />,
  //   text: 'Account',
  //   path: '/dashboard/account',
  // },
  {
    icon: (props: any) => <Card {...props} />,
    text: 'Transactions',
    path: '/dashboard/transactions',
  },
]

const accountItems = [
  {
    text: 'Manage Profile',
    path: '/dashboard/account/manage-profile',
  },
  {
    text: 'Session Settings',
    path: '/dashboard/account/session-settings',
  },
  {
    text: 'Away Period Settings',
    path: '/dashboard/account/away-settings',
  },
  {
    text: 'Withdraw Funds',
    path: '/dashboard/account/withdraw-funds',
  },
  {
    text: 'Bank Settings',
    path: '/dashboard/account/bank-settings',
  },
  {
    text: 'Password Settings',
    path: '/dashboard/account/password-settings',
  },
]

const SideNav = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { show } = useIntercom()
  const dispatch = useDispatch()

  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const handleCloseModal = () => {
    setShowLogoutModal(!showLogoutModal)
  }

  return (
    <SideNavStyle displaySideNav={true}>
      <SideNavTop>
        <Link to={'/'}>
          <img src={TherapistLogo} loading={'lazy'} alt={'mytherapist logo'} />
        </Link>
      </SideNavTop>
      <SideNavCenter>
        {navItems.map((item, index) => {
          let path =
            location.pathname.split('/')[
              location.pathname.split('/').length - 1
            ]
          return (
            <NavItem
              key={index}
              active={path.includes(item.text.toLowerCase())}
              onClick={() => {
                navigate(`${item.path.toLowerCase()}`)
                // close the session details modal if open when navigation is occuring
                dispatch(toggleSessionDetails())
                console.log('eheheheh')
              }}
            >
              {item.icon({
                fill: path.includes(item.text.toLowerCase())
                  ? '#1B1D3E'
                  : 'none',
              })}
              <CTAButtonText
                color={
                  path.includes(item.path.toLowerCase()) ? '#1B1D3E' : '#344054'
                }
              >
                {item.text}{' '}
              </CTAButtonText>
            </NavItem>
          )
        })}

        <DropdownMenu name="Account & Settings">
          {accountItems.map((item, index) => {
            let path =
              location.pathname.split('/')[
                location.pathname.split('/').length - 1
              ]

            let itemPath = item.path.split('/')[item.path.split('/').length - 1]

            return (
              <NavItem
                key={index}
                active={path === itemPath}
                onClick={() => navigate(`${item.path}`)}
              >
                <CTAButtonText
                  color={
                    path.includes(item.path.toLowerCase())
                      ? '#1B1D3E'
                      : '#344054'
                  }
                >
                  {item.text}
                </CTAButtonText>
              </NavItem>
            )
          })}
        </DropdownMenu>

        <DropdownMenu name="Support">
          <a
            href="https://mytherapistdotng.freshdesk.com/support/home"
            target="_blank"
            rel="noreferrer"
          >
            <HelpDesk width={24} height={25} color="#667085" />
            <CTAButtonText color="#344054">Help Desk</CTAButtonText>
          </a>
          <IntercomBtn onClick={show}>
            <Chat width={24} height={25} color="#667085" />
            <CTAButtonText color="#344054">Chat Support</CTAButtonText>
          </IntercomBtn>
        </DropdownMenu>
      </SideNavCenter>

      <SideNavFooter>
        <div onClick={handleCloseModal}>
          <LogoutIcon />
          <BodyNormal color={'#344054'}>Logout</BodyNormal>
        </div>
        <Hr />
      </SideNavFooter>

      {/* LOGOUT MODAL */}
      <Modal
        show={showLogoutModal}
        handleAction={handleCloseModal}
        width={'500px'}
      >
        <LogoutModal closeModal={handleCloseModal} />
      </Modal>
    </SideNavStyle>
  )
}

export default SideNav
