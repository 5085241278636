import { useEffect, useState } from 'react'
import OneSignal from 'react-onesignal'
import { toRem } from '../../helpers'
import { device } from 'helpers/breakpoints'
import Header from './Header'
import styled from 'styled-components'
import Requests from 'components/libs/Requests'
import Analytics from 'components/libs/Analytics'
import { BodyNormalBold } from 'components/base/typography'
import { Caution, Cancel } from 'assets/svg'
import { ScrollBar } from 'styles/global'
import useFetchAccount from 'hooks/account/useFetchAccount'
import { accountService } from 'services'
import QuickActions from 'components/libs/QuickActions'
import History from 'components/libs/History'

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`

const Wrapper = styled.main`
  height: 100%;
  overflow: auto;
  ${ScrollBar}
`

const Body = styled.div`
  padding: 16px 30px;
  padding-bottom: 50px;

  @media ${device.mobileL} {
    padding: 16px;
  }
`
const Notice = styled.div`
  width: 100%;
  height: 68px;
  color: #344054;
  background: #fef7c3;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 31px;

  & span {
    width: 25px;
    height: 25px;
    margin-left: auto;

    & svg {
      color: #344054;
    }
  }

  @media ${device.tablet} {
    gap: 10px;
    padding: 10px 24px;
    height: fit-content;

    ${BodyNormalBold} {
      flex: 4;
      font-size: ${toRem(12)};
    }
  }
`

const TableContainer = styled.div`
  height: 500px;
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 20px;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }
`

const Dashboard = () => {
  const { data } = useFetchAccount()
  const [showScreenSizeNotice, setShowScreenSizeNotice] = useState(
    window.innerWidth <= 800
  )

  async function runOneSignal() {
    await OneSignal.init({
      appId: '8c28dde7-c976-4b5e-b2bd-0db32c4180e0',
      allowLocalhostAsSecureOrigin: true,
    })
    OneSignal.showSlidedownPrompt()

    OneSignal.on('subscriptionChange', () => {
      pushNotifications()
    })

    pushNotifications()
  }

  useEffect(() => {
    runOneSignal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pushNotifications = async () => {
    const ID = await OneSignal.getUserId()

    const isPushNotificationEnabled =
      await OneSignal.isPushNotificationsEnabled()

    if (isPushNotificationEnabled) {
      accountService.subscribeToNotifications({
        type: 'player',
        device: 'web',
        value: ID,
      })
    } else {
      return
    }
  }

  const approved = data?.is_approved
  return (
    <Wrapper>
      <Header />
      <Body>
        {data && !approved && (
          <Notice>
            <Caution />
            <BodyNormalBold>
              Your account is being reviewed. You would get a mail when our
              verification is complete.
            </BodyNormalBold>
            <span>
              <Cancel />
            </span>{' '}
          </Notice>
        )}

        {/* THIS CHECKS THE SCREEN WIDTH AND DISPLAYS THIS COMPONENT */}
        {showScreenSizeNotice && (
          <Notice>
            <Caution width={18} />
            <BodyNormalBold>
              To avoid issues conducting sessions, this portal is best
              experienced on a Desktop or Tablet Device.
            </BodyNormalBold>
            <span>
              <Cancel onClick={() => setShowScreenSizeNotice(false)} />
            </span>{' '}
          </Notice>
        )}

        <Content>
          <Analytics />
          <Requests />
        </Content>
        <TableContainer>
          <QuickActions />
          <History />
        </TableContainer>
      </Body>
    </Wrapper>
  )
}

export default Dashboard
