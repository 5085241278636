import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import moment from 'moment'
import SideModal from 'components/libs/Modal/SideModal'
import SessionDetails from 'components/libs/SessionDetails'
import { withUseQuery } from 'hoc/withUseQuery'
import { TherapistToast } from 'helpers'

import { StatusCard, Profile, ViewMore } from 'pages/bookings/style'

import {
  DetailsContainer,
  Grid,
  TableWrapper,
  Analytics,
  DetailItemContainer,
  DetailItemInfo,
} from './styles'

import { RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSessionDetails } from 'store/modules/modals/actions'
import Table from 'components/libs/Table'
import {
  BodyNormal,
  BodySmall,
  TitleThree,
  BodySmallSemiBold,
} from 'components/base/typography'
import { Box, Container } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import {
  Kebab,
  InfoIcon,
  Time,
  Users,
  Copy,
  Mail,
  Profile as ProfileIcon,
  Globe,
  CalendarAlt,
} from 'assets/svg'
import Dropdown from 'components/libs/Dropdown'

import 'styles/calendar.css'
import { Card, InfoBox, Flex, IconBox } from '../Analytics/style'
import Tooltip from '../Tooltip'
import useFetchClient from 'hooks/clients/useFetchClient'
import useFetchClientAppointments from 'hooks/clients/useFetchClientAppointments'

export interface DetailsType {
  name: string
  reason: string
  description: string
  time: string
  image: string
  id: string
  appointmentId: number
  type: string
}

interface Props {
  [key: string]: any
}

const Overview = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const [details, setDetails] = useState<DetailsType>({
    name: '',
    image: '',
    time: '',
    description: '',
    reason: '',
    id: '',
    appointmentId: 0,
    type: '',
  })

  const handleToggle = () => {
    setShowDetailsModal(false)
  }

  const { data: upcomingAppointments, isLoading: loading } =
    useFetchClientAppointments(Number(id), 'accepted')
  const { data: clientData, isLoading: loadingClientInfo } = useFetchClient(
    Number(id)
  )

  const appointmentsLists = upcomingAppointments?.appointments

  let appointments = appointmentsLists

  const handleOpenModal = (sessionDetails: any) => {
    setShowDetailsModal(true)
    const sessionObject = {
      name: `${sessionDetails.userDisplayName}`,
      image: sessionDetails.user.avatar,
      time: sessionDetails.started_at,
      description: sessionDetails.note,
      reason: sessionDetails.reason,
      id: sessionDetails.user.id,
      appointmentId: sessionDetails.id,
      type: sessionDetails.type,
    }

    setDetails(sessionObject)
  }

  const handleCloseModal = () => {
    setShowDetailsModal(false)
  }

  const canStartSession = (
    appointmentID: number,
    time: string = details.time
  ) => {
    const sessionTime = moment(time).format()
    const now = moment(new Date())
    const difference = now.diff(sessionTime, 'minutes')

    const diff = difference < 70 && difference >= -20

    if (diff) {
      handleStartSession(appointmentID)
    } else {
      TherapistToast.error({
        msg: `Sorry, you can only join the session at least 10 mins before it starts.`,
      })
    }
  }

  const handleStartSession = async (appointmentID: number) => {
    navigate(`/dashboard/bookings/${appointmentID}`)
  }

  const analyticsData = [
    {
      name: 'Missed',
      value: clientData?.client_appointments_missed_count ?? 0,
      icon: <Time />,
      description:
        'Missed Appointments are appointments where the client did not show up.',
      information: 'Missed sessions will appear here',
    },
    {
      name: 'Completed',
      value: clientData?.client_appointments_count ?? 0,
      icon: <Time />,
      description:
        'This is the total number of this client has completed with you',
      information: 'Completed sessions will appear here',
    },
  ]

  return (
    <>
      <SideModal
        show={showDetailsModal}
        handleAction={handleToggle}
        width={'380px'}
        direction="right"
        display="desktop"
      >
        <SessionDetails
          show={showDetailsModal}
          name={details.name}
          image={details.image}
          time={details.time}
          reason={details.reason}
          appointmentID={details.appointmentId}
          type={details.type}
          description={details.description}
          handleClose={handleCloseModal}
          handleStartSession={canStartSession}
          userId={details.id}
        />
      </SideModal>

      <Grid>
        <div>
          <DetailsContainer>
            {loadingClientInfo && <InfoSkeleton />}
            {clientData && !loadingClientInfo && (
              <>
                <DetailItem
                  title="Client_ID"
                  value={clientData?.id}
                  icon={<Users />}
                  canCopy
                />
                <DetailItem
                  title="Username"
                  value={clientData?.username}
                  icon={<Mail />}
                  canCopy
                />
                <DetailItem
                  title="First Name"
                  value={clientData?.first_name}
                  icon={<ProfileIcon />}
                  canCopy={false}
                />
                <DetailItem
                  title="Last Name"
                  value={clientData?.last_name}
                  icon={<ProfileIcon />}
                  canCopy={false}
                />
                <DetailItem
                  title="Gender"
                  value={clientData?.gender}
                  icon={<ProfileIcon />}
                  canCopy={false}
                />
                <DetailItem
                  title="Subscription Tier"
                  value={clientData.has_prime ? 'Prime' : 'Basic'}
                  icon={<Globe />}
                  canCopy={false}
                />
                <DetailItem
                  title="Date Assigned"
                  value={
                    clientData.client_assigned_at
                      ? moment(clientData.client_assigned_at).format(
                          'dddd, MMM d, YYYY'
                        )
                      : '-------'
                  }
                  icon={<CalendarAlt />}
                  canCopy={false}
                />
              </>
            )}
          </DetailsContainer>
          <Analytics>
            {analyticsData.map((stats: any) => (
              <Card key={stats.name}>
                <InfoBox>
                  <BodySmallSemiBold color={'#5D6673'}>
                    {stats.name}
                  </BodySmallSemiBold>
                  <Tooltip content={stats.description}>
                    <InfoIcon />
                  </Tooltip>
                </InfoBox>

                <Flex>
                  <TitleThree>{stats.value}</TitleThree>
                  <IconBox>{stats.icon}</IconBox>
                </Flex>
                {/* {stats} */}
                <BodySmall color={'#9EA3AB'} fontSize={'12px'}>
                  {stats.information}
                </BodySmall>
              </Card>
            ))}
          </Analytics>
        </div>

        <TableWrapper>
          <Table
            label=""
            columns={[
              {
                title: 'Upcoming appointment',
                dataIndex: 'client',
                key: 'client',
              },
              {
                title: 'Date & Time',
                dataIndex: 'dateTime',
                key: 'dateTime',
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
              },
              {
                title: '',
                dataIndex: 'view',
                key: 'view',
              },
            ]}
            loading={loading}
            dataSource={
              appointments && appointments.length > 0
                ? appointments.map((data: Props) => {
                    return {
                      key: `${data.id}`,
                      client: (
                        <Profile key={data.id}>
                          <img
                            src={data?.user?.avatar}
                            alt={data.userDisplayName}
                          />
                          <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                            {data?.userDisplayName}
                          </BodyNormal>
                        </Profile>
                      ),
                      type: (
                        <BodyNormal fontSize={'14px'} color={'#1B1D3E'}>
                          {data?.type}
                        </BodyNormal>
                      ),
                      dateTime: (
                        <>
                          <BodyNormal color={'#1B1D3E'} fontSize={'14px'}>
                            {moment(data?.started_at).format('Do MMM YYYY')}
                          </BodyNormal>
                          <BodyNormal
                            color={'#9EA3AB'}
                            style={{ textTransform: 'uppercase' }}
                            fontSize={'14px'}
                          >
                            {moment(data.started_at).format('h:mm a')} -
                            {moment(data.ended_at).format('h:mm a')}
                          </BodyNormal>
                        </>
                      ),
                      status: (
                        <StatusCard status={data.status}>
                          <BodyNormal fontSize={'14px'}>
                            {data.status.charAt(0).toUpperCase() +
                              data.status.slice(1)}
                          </BodyNormal>
                        </StatusCard>
                      ),
                      view: (
                        <Dropdown
                          onStartSession={canStartSession}
                          appointment={data}
                          onViewDetails={handleOpenModal}
                          appointmentID={data.id}
                          buttonComponent={
                            <ViewMore>
                              <Kebab width={20} color="#1B1D3E" />
                            </ViewMore>
                          }
                        />
                      ),
                    }
                  })
                : []
            }
            // paginationData={appointmentsData?.meta?.links}
            // currentPage={currentPage}
            // handlePagination={handlePagination}
            handleAction={handleOpenModal}
          />
        </TableWrapper>
      </Grid>
    </>
  )
}

interface DetailItemProps {
  title: string
  value: string
  canCopy: boolean
  icon: React.ReactNode
}

const DetailItem: React.FC<DetailItemProps> = ({
  title,
  value,
  canCopy,
  icon,
}) => {
  const [copied, setCopied] = useState(false)

  const changeCopiedState = () => {
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(value)
      setCopied(true)
      changeCopiedState()
    } catch (err) {
      setCopied(false)
    }
  }
  return (
    <DetailItemContainer>
      <DetailItemInfo>
        {icon}
        <div>
          <BodyNormal color={'#667185'} fontSize={'12px'}>
            {title}
          </BodyNormal>
          <BodyNormal fontSize={'14px'} fontWeight={500}>
            {value}
          </BodyNormal>
        </div>
      </DetailItemInfo>

      {canCopy && (
        <button onClick={copyLink}>
          {copied ? (
            <BodySmallSemiBold color={'#1ab265'}>Copied!</BodySmallSemiBold>
          ) : (
            <Copy />
          )}
        </button>
      )}
    </DetailItemContainer>
  )
}

export const InfoSkeleton = () => {
  return (
    <Box height={'200px'} padding={'20px'} marginBottom={'200px'}>
      <Skeleton />
      <Skeleton width="100%" height="40%" count={4} />
    </Box>
  )
}

export default withUseQuery(Overview)
