import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'components/libs/Button'
import { ButtonState } from 'components/libs/Button/style'
import {
  Wrapper,
  Heading,
  ViewHistoryLink,
  Appointments,
  Avatar,
  Date,
  Time,
  TimeDateContainer,
  Action,
} from './style'
import { SubHeadingBold, BodyNormalSemiBold } from 'components/base/typography'
import { BodySmall } from 'components/base/typography'
import { ChevronRightIcon } from '@radix-ui/react-icons'
import useAppointments from 'hooks/appointments/useAppointments'
import { TherapistToast } from 'helpers'
import moment from 'moment'
import { CalendarAlt, Clock } from 'assets/svg'
import { DetailsType } from 'pages/bookings'
import SideModal from 'components/libs/Modal/SideModal'
import SessionDetails from 'components/libs/SessionDetails'
import { NoRequest as NoHistory } from '../Requests'
import { Empty } from 'assets/svg'
import { Stack } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'

export const canStartSession = (startTime: string) => {
  let canStart
  const sessionTime = moment(startTime).format()
  const now = moment()
  const difference = now.diff(sessionTime, 'minutes')

  const diff = difference < 70 && difference >= -20

  if (diff) {
    canStart = true
  } else {
    canStart = false
  }

  return canStart
}

const History = () => {
  const { data, isLoading } = useAppointments(1)
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const appointments = data?.appointments

  const sortHistory = () => {
    let historyList
    const filteredList = appointments
      ?.filter(
        (appointment: Record<string, any>) => appointment.status !== 'pending'
      )
      .sort((a: Record<string, any>, b: Record<string, any>) =>
        moment(a.started_at).diff(moment(b.started_at))
      )

    // Get today's date

    const today = moment().startOf('day')

    // Filter appointments happening today
    const appointmentsToday = filteredList?.filter(
      (appointment: Record<string, any>) => {
        const appointmentDate = moment(appointment.started_at).startOf('day')
        return appointmentDate.isSame(today, 'day')
      }
    )

    if (appointmentsToday && filteredList) {
      historyList = Array.from(new Set([...appointmentsToday, ...filteredList]))
    }

    return historyList
  }

  const history = sortHistory()

  const startSession = (startTime: string, appointmentID: number) => {
    const start = canStartSession(startTime)

    if (start) {
      handleStartSession(appointmentID)
    } else {
      TherapistToast.error({
        msg: 'Sorry, you can only join the session at least 10 mins before it starts.',
      })
    }
  }

  const navigate = useNavigate()

  const [details, setDetails] = useState<DetailsType>({
    name: '',
    image: '',
    time: '',
    description: '',
    reason: '',
    id: '',
    appointmentId: 0,
    type: '',
  })

  const handleToggle = () => {
    setShowDetailsModal(false)
  }

  const handleOpenModal = (sessionDetails: any) => {
    setShowDetailsModal(!showDetailsModal)

    const sessionObject: DetailsType = {
      name: sessionDetails.userDisplayName,
      image: sessionDetails.user.avatar,
      time: sessionDetails.started_at,
      description: sessionDetails.note,
      reason: sessionDetails.reason,
      id: sessionDetails.user.id,
      appointmentId: sessionDetails.id,
      type: sessionDetails.type,
    }

    setDetails(sessionObject)
  }

  const handleCloseModal = () => {
    setShowDetailsModal(!showDetailsModal)
  }

  const handleStartSession = async (appointmentID: number) => {
    navigate(`/dashboard/bookings/${appointmentID}`)
  }

  return (
    <>
      <Wrapper>
        <Heading>
          <SubHeadingBold>My History</SubHeadingBold>
          <ViewHistoryLink to="/dashboard/bookings">
            <BodySmall marginLeft={2}>See all</BodySmall>
            <ChevronRightIcon />
          </ViewHistoryLink>
        </Heading>

        {/* LOADING SKELETON */}

        {isLoading && (
          <Stack spacing="24px" padding={5}>
            <Skeleton height="50px" count={7} />
          </Stack>
        )}

        {/* WHEN THERE IS NO HISTORY */}
        {history?.length === 0 && !isLoading && (
          <NoHistory>
            <Empty />
            <BodySmall> You have no history yet </BodySmall>
          </NoHistory>
        )}

        {/* WHEN THERE IS HISTORY */}

        {history?.length !== 0 &&
          history?.map((appointment: Record<string, any>) => (
            <Appointments key={appointment.id}>
              <Avatar src={appointment.user.avatar} />
              <div>
                <BodyNormalSemiBold color="#101928">
                  {appointment.userDisplayName}
                </BodyNormalSemiBold>
                <TimeDateContainer>
                  <Date>
                    <CalendarAlt />
                    <BodySmall>
                      {moment(appointment.started_at).format('L')}
                    </BodySmall>
                  </Date>
                  <Time>
                    <Clock />
                    <BodySmall>
                      {moment(appointment.started_at).format('LT')}
                    </BodySmall>
                  </Time>
                </TimeDateContainer>
              </div>
              <Action>
                {canStartSession(appointment.started_at) ? (
                  <Button
                    variant={ButtonState.PRIMARY}
                    padding={'0px 30px'}
                    border_radius="8px"
                    type={'button'}
                    onClick={() => handleOpenModal(appointment)}
                  >
                    Start Session
                  </Button>
                ) : (
                  <Button
                    variant={ButtonState.OUTLINE}
                    padding={'0px 30px'}
                    border_radius="8px"
                    type={'button'}
                    onClick={() => handleOpenModal(appointment)}
                  >
                    View Details
                  </Button>
                )}
              </Action>
            </Appointments>
          ))}
      </Wrapper>

      <SideModal
        show={showDetailsModal}
        handleAction={handleToggle}
        width={'380px'}
        direction="right"
        display="desktop"
      >
        <SessionDetails
          show={showDetailsModal}
          userId={details.id}
          name={details.name}
          image={details.image}
          time={details.time}
          reason={details.reason}
          appointmentID={details.appointmentId}
          description={details.description}
          type={details.type}
          handleClose={handleCloseModal}
          handleStartSession={() =>
            startSession(details.time, details.appointmentId)
          }
        />
      </SideModal>
    </>
  )
}

export default History
